import React         from 'react';
import Img           from 'gatsby-image';
import { Flex, Box } from '@chakra-ui/react';
import { graphql }   from 'gatsby';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import { findMedia }     from '@interness/web-core/src/components/_helpers';


function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Kortenhaus</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Seien Sie herzlich willkommen bei Juwelier Kortenhaus in Mettmann, im Niederbergischen Land.</p>
            <p>Seit sechs Generationen widmen wir uns mit Leidenschaft, kreativer Präzision und großer Freude den verrinnenden Sekunden. Dabei verstehen wir den Gang der Uhren aus vergangenen Jahrhunderten genauso wie die moderne Elektronik der Gegenwart.</p>
            <MoreExpander title='weiterlesen'>
              <p>Sie sind herzlich eingeladen, in der angenehmen Atmosphäre unserer Geschäftsräume zu verweilen und unser fein ausgewähltes Sortiment an Trauringen und Eheringen, traumhaften Schmuck und feinen Uhren zu entdecken.</p>
              <p>Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. Wir bieten Ihnen perfekten Service uns erstklassige Reparaturen.</p>
              <p>Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen ist für das Team von Juwelier Kortenhaus selbstverständlich.<br/>
              Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
              <p>Wir freuen uns auf Ihren Besuch<br/>Ihr Team von Juwelier Kortenhaus.</p>
            </MoreExpander>
          </span>
        </section>
        <Spacer/>
        <section>
          <Display/>
          <Spacer/>
        </section>
        <section>
          <Heading tag='h2' subtitle='Machen Sie Ihr Altgold zu Bargeld'>Altgold Ankauf</Heading>
          <Flex flexWrap='wrap'>
            <Box width={['100%', '50%', '50%', '50%']} pr={20} style={{ textAlign: 'justify' }}>
              <p>Bei Ihrem Juwelier Kortenhaus stehen faire Beratung und Ihre Zufriedenheit an erster Stelle.</p>
              <p>Altgold-Verkauf ist Vertrauenssache. Wir als erfahrene Gold-Experten sichern Ihnen eine unverbindliche
                und
                kostenlose Beratung zu. Wir begutachten Ihr Altgold auf den Feingehalt und den zu erwartenden Erlös.</p>
              <p>Ob Sie Ihr Schmuck-Gold zu den so ermittelten Bedingungen verkaufen möchten entscheiden Sie dann
                selbst.</p>
              <MoreExpander title='So funktioniert es'>
                <h3>So gehen wir beim Altgold Ankauf vor</h3>
                <p>
                  Zunächst sortieren wir Ihr Altgold (Schmuckgold, Zahngold, Münzen etc.) nach Legierungsart. Bei nicht
                  gestempelten Materialien wird der Feingehalt durch Probiersäuren bestimmt. Verwendbare Edelsteine
                  können ausgefasst und verrechnet werden oder zur weiteren Verarbeitung zur Verfügung gestellt werden.
                  Gerne beraten unsere Goldschmiedemeister Sie zu diesem Thema.
                </p>
                <h3>Schritt für Schritt zum fairen Preis</h3>
                <p>Zahngold wird zur genauen Gewichts- und Legierungsbestimmung aus dem Zahn geklopft und komplett vom
                  übrigen Material gelöst. Bei größeren Altgold-Mengen sortieren unsere Goldschmiedemeister Ihr Altgold
                  nach Feingehalt und stellen Ihnen eine Übersicht zusammen, die folgende Angaben enthält:</p>

                <ul>
                  <li>enthaltene Legierungen (Feingehalt)</li>
                  <li>Gewicht des Altgolds</li>
                  <li>Der kalkulierte Ankaufspreis pro Gramm</li>
                  <li>Gesamt-Ankaufspreis Ihres Goldes</li>
                </ul>

                <p>Unsere Kalkulation richtet sich nach den aktuellen Tageskursen. Die anfallenden Bearbeitungs-,
                  Verarbeitungs-, Porto-, Einschmelz- und Analysekosten sind im Ankaufspreis bereits berücksichtigt.
                  Nach der Ermittlung des Ankaufspreises entscheiden sie, ob Sie Ihr Altgold an uns verkaufen möchten.
                  Im positiven Fall erhalten Sie sofort Bargeld.</p>
              </MoreExpander>
            </Box>
            <Box width={['100%', '50%', '50%', '50%']} pl={20}>
              <Img fluid={findMedia(props.data.directusMediaCollection.images, '157-kortenhaus-altgold').childImageSharp.fluid} />
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    directusMediaCollection(name: {eq: "headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;